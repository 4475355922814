<template>
  <div>
    <b-row>
      <b-col md="12" v-if="compressImage">
        <b-form-checkbox v-model="compressor" 
                          switch 
                          size="sm" 
                          class="pull-left">
          Comprimir Imágenes
        </b-form-checkbox>            
      </b-col>
      <b-col md="12" v-if="addWaterMark && existImageWatermark && hasWatermark">
        <b-form-checkbox v-model="watermark" 
                          switch 
                          size="sm" 
                          class="pull-left">
          Agregar Marca de Agua
        </b-form-checkbox>            
      </b-col>      
      <b-col md="12">
        <vue-upload-multiple-image
          @before-remove="remove"
          @upload-success="upload"
          @edit-image="edit"
          :dataImages="dataImages"
          :showPrimary="showPrimaryImage"
          :multiple="multipleImage"
          :maxImage="maxImage"
          dragText="Arrastrar imágenes"
          browseText="o Seleccionar"
          markIsPrimaryText="Predeterminar"
          primaryText="Por defecto"
          popupText="Esta imagen se mostrará como predeterminada"
          :idUpload="'upload' + typeImage" 
          :idEdit="'edit' + typeImage"
          dropText="Suelta tus archivos aquí ...">
        </vue-upload-multiple-image> 
      </b-col>
    </b-row>    
  </div>
</template>

<script>
  import VueUploadMultipleImage from 'vue-upload-multiple-image' 
  import serviceAPI from './services'
  import Compressor from 'compressorjs';
  import watermark from 'watermarkjs';
  import Param from '@/config/parameters'  
  import Helper from '@/handler/helper' 

  export default {
    components: {
      VueUploadMultipleImage,
    },  
    props: {
      multipleImage: {
        type: Boolean,
        default: false
      }, 
      showPrimaryImage: {
        type: Boolean,
        default: false
      },
      typeImage: {
        type: String,
        required: true,
      },
      images: {
        type: Array,
        required: true,        
      },
      maxImage: {
        type: Number,
        required: false,
        default: 8,
      },
      compressImage: {
        type: Boolean,
        default: false,
      },
      addWaterMark: {
        type: Boolean,
        default: false,        
      }      
    },
    data() {
      return {
        compressor: true,
        compressorQuality: 0.6,
        existImageWatermark: false,
        watermark: true,
        watermarkImageBase64: '',  
        parameters: {
          hasWatermark: Helper.hasParametersAccess(Param.P57),
        }
      }
    },
    computed: {
      dataImages: {     
        get: function () {          
          return this.images;
        },
        set: function (value) {
          this.$emit('load-image', value)
        }                 
      }
    },    
    mounted() {
      this.validWaterMark()
    },
    methods: {      
      remove (index, done, fileList) {
        var r = confirm("¿Eliminar Imagen?")
        if (r == true) {
          done()
        }
        this.dataImages = fileList        
      },
      upload (formData, index, fileList) {            
        formData.forEach((element)=>{          
          const reader = new FileReader();
          reader.readAsDataURL(element);
          reader.onloadend = () => {               
            fileList[index]['path'] = reader.result 
            this.processImage(index, fileList)

            this.dataImages = fileList
          } 
        })                      
      },
      edit (formData, index, fileList) {                   
        formData.forEach((element)=>{
          const reader = new FileReader();
          reader.readAsDataURL(element);
          reader.onloadend = () => {                
            fileList[index]['path'] = reader.result
            this.processImage(index, fileList)

            this.dataImages = fileList
          }          
        })            
      },      
      dataURItoBlob(dataURI) {
          // convert base64/URLEncoded data component to raw binary data held in a string
          var byteString;
          if (dataURI.split(',')[0].indexOf('base64') >= 0)
              byteString = atob(dataURI.split(',')[1]);
          else
              byteString = unescape(dataURI.split(',')[1]);

          // separate out the mime component
          var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

          // write the bytes of the string to a typed array
          var ia = new Uint8Array(byteString.length);
          for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
          }

          return new Blob([ia], {type:mimeString});
      },  
      validWaterMark() {
        var settings = localStorage.getItem(window.location.origin + '_settings')
        if(settings==undefined) {settings = null} else {settings = JSON.parse(settings)}

        if(settings.image_watermark) {
          this.existImageWatermark = true        
          this.getImageWatermark()
        } else {
          this.existImageWatermark = false           
        }

        if(this.addWaterMark && this.existImageWatermark) {
          this.watermark = true        
        } else {
          this.watermark = false     
        }        
      },      
      getImageWatermark() {
        var result = serviceAPI.getImageWaterMark()

        result.then((response) => {
          this.watermarkImageBase64 = response.data          
        })        
      },
      processImage(index, fileList) {
        var base64Data = this.dataURItoBlob(fileList[index].path)        

        if(this.compressor && this.compressImage) {                                
          if(this.watermark) {
            // SI - COMPRESS
            // SI - WATERMARK
            const _image = base64Data;
            const _logo = this.dataURItoBlob(this.watermarkImageBase64)                                            
            watermark([_image, _logo])
            .image(watermark.image.center(0.3))
            .then(img => {                      
              base64Data = this.dataURItoBlob(img.src)
                            
              new Compressor(base64Data, {
                quality: this.compressorQuality,
                strict: true,
                success(result) {
                    var reader = new FileReader();
                    reader.readAsDataURL(result); 
                    reader.onloadend = function() {
                      var base64data = reader.result;     
                    
                      fileList[index].path = base64data 
                      this.dataImages = fileList        
                    }            
                },
              });                                   
            });                    
          } else {
            // SI - COMPRESS
            // NO - WATERMARK
            new Compressor(base64Data, {
              quality: this.compressorQuality,
              strict: true,
              success(result) {
                  var reader = new FileReader();
                  reader.readAsDataURL(result); 
                  reader.onloadend = function() {
                    var base64data = reader.result;     
                  
                    fileList[index].path = base64data 
                    this.dataImages = fileList        
                  }            
              },
            });                                                  
          }          
        } else {              
          if(this.watermark) {
            // NO - COMPRESS
            // SI - WATERMARK
            const _image = base64Data;
            const _logo = this.dataURItoBlob(this.watermarkImageBase64)                                            
            watermark([_image, _logo])
            .image(watermark.image.center(0.3))
            .then(img => {                      
              base64Data = img.src

              fileList[index].path = base64Data 
              this.dataImages = fileList                                          
            });                    
          }           
        }             
      }
    }    
  }      
</script>